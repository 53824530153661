<template>
  <div class="backups-table">
    <!--    <div v-if="!dataset.length" class="vps-info__loader">-->
    <div v-if="loading" class="vps-info__loader">
      <base-loader class="vps-info__loader-item" />
    </div>
    <base-table v-else :columns="tableHead" :list="dataset" class="backups-table__table">
      <backups-table-row
        v-for="item in dataset"
        :key="item.Uuid"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import BackupsTableRow from './BackupsTableRow';
export default {
  name: 'BackupTable',
  components: { BaseTable, BackupsTableRow },
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    // loading: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data() {
    return {
      tableHead: [
        // {
        //   key: 'id',
        //   label: this.$t('tableHead.id'),
        //   style: {
        //     width: '44px',
        //   },
        // },
        {
          key: 'snapshot',
          label: this.$t('tableHead.snapshot'),
          style: {
            //flex: '1 1 auto',
            width: '300px',
            maxWidth: '300px',
            minWidth: '300px',
            verticalAlign: 'baseline',
          },
          // sort: {
          //   prop: 'title',
          //   order: 'asc',
          // },
        },
        {
          key: 'server',
          label: this.$t('tableHead.server'),
          style: {
            width: '300px',
            maxWidth: '300px',
            minWidth: '300px',
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          style: {
            width: '230px',
            minWidth: '230px',
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'size',
          label: this.$t('tableHead.size'),
          style: {
            width: '100px',
            minWidth: '100px',
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '115px',
          },
          // sort: {
          //   prop: 'status',
          //   order: 'asc',
          // },
        },
        {
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      loading: false,
    };
  },
  methods: {},
};
</script>
<i18n>
{
  "ru": {
    "tableHead": {
        "snapshot": "Название снэпшота",
        "server": "Сервер",
        "date": "Дата",
        "size": "Размер",
        "status": "Статус"
    }
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.backups-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }
    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
