<template>
  <div class="backup-page">
    <h1 class="backup-page__title page-title">
      {{ $t('title') }}
    </h1>
    <!--    <page-block :title="$t('acronis.title')" class="backup-page-backup__block">-->
    <!--      <backup-card class="backup-page-card"></backup-card>-->
    <!--    </page-block>-->
    <!--    <div class="backup-page-controllers">-->
    <!--      <custom-title class="backup-page-title" text="FTP Бэкапы"></custom-title>-->
    <!--      <span>Новый FTP бэкап + </span>-->
    <!--    </div>-->
    <!--    <table-with-controllers :titles="[]" :dataset="[]"></table-with-controllers>-->
    <page-block :title="$t('created.title')" class="backup-page-backup__block">
      <!--      <plain-button slot="action" color="primary" nowrap @click="addNewBackup">  разобраться с компонентом-->
      <!--        {{ $t('created.btn') }}-->
      <!--      </plain-button>-->
      <div class="backup-page-snapshot">
        <transition name="fade" mode="out-in">
          <backups-table :dataset="sortedDataset()" :tariff="list" />
          <!--          <backups-table :dataset="sortedDataset()" :tariff="list" :loading="isLoading" />-->
        </transition>
      </div>
    </page-block>
  </div>
</template>

<script>
// import BackupCard from '../../components/SaasCard.vue';
import storeMixin from '@/layouts/VPS/mixins/index';
import BackupsTable from '@/layouts/Backup/components/BackupsTable';
import createBackup from '@/mixins/backup';

export default {
  name: 'MainBackupPage',
  components: {
    // BackupCard,
    BackupsTable,
  },
  mixins: [storeMixin, createBackup],
  data() {
    return {
      // isLoading: true,
    };
  },
  computed: {
    nameVps() {
      return this.$store.getters['moduleVPS/getNamesVps'];
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    // backups() {
    //   return this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
    // },
  },
  mounted() {
    // this.isLoading = true;
    if (this.$store.state.moduleVPS.key) this.setBackupsInfo();
    if (!this.list.length) {
      this.fetchList()
        .then(() => {
          if (this.list.length && !this.vmKey) {
            this.getVmKey();
          }
        })
        .catch(e => {
          if (e.status && e.status === 520) this.isServerError = true;
        });
    } else this.getVmKey();
    setTimeout(() => {
      // this.isLoading = false;
    }, 1000);
  },
  methods: {
    async setBackupsInfo() {
      await this.$store.dispatch('moduleVPS/moduleVpsOrder/setBackupsInfo');
      await this.$store.dispatch('moduleVPS/fetchBackupNameList');
      // await this.$store.dispatch('moduleVPS/moduleVpsOrder/updateBackups', this.sortedDataset());
    },
    sortedDataset() {
      return this.dataset.sort((x, y) => x.id - y.id);
    },
    getVmKey() {
      const tariff = this.list.find(item => this.tools.gotoserver.isEnable(item));
      this.$store.dispatch('moduleVPS/fetchDetail', { id: tariff.id }).then(() =>
        this.$store.dispatch('moduleVPS/getVmKey', tariff).then(data => {
          if (data) this.setBackupsInfo();
          else setTimeout(() => this.setBackupsInfo(), 1500);
        })
      );
    },
    //TODO новый компонент создания бэкапа надо сделать
    addNewBackup() {
      // if (this.stateServerIsStopped)
      this.createBackup(this.$t('new_backup'), {
        // tariff: this.tariff,
        tariff: this.list[1],
        vpsList: this.nameVps,
      });
      // else {
      //   this.showResModal(this.$t('server_state.error_backup'));
      // }
    },
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleVPS/moduleVpsOrder/reset');
    // this.$store.dispatch('moduleVPS/moduleVpsOrder/updateBackups', this.sortedDataset());
    // console.log(_to.path, 'куда', _from.path, 'откуда');
    // this.$store.dispatch('moduleVPS/reset');
    next();
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Бэкапы",
    "acronis": {
      "title": "Облачные бэкапы"
    },
    "created": {
      "btn": "+ Новый снапшот",
      "title": "Снапшоты"
    },
    "new_backup": "Создать резервную копию"
  }
}
</i18n>

<style lang="scss">
.backup-page {
  display: flex;
  flex-direction: column;
  &-title {
    &:nth-child(2) {
      margin-top: 40px;
    }
    // margin-top: 40px;
  }
  &-card {
    margin-top: 20px;
  }
  &-controllers {
    margin-top: 40px;
    margin-bottom: 22px;
    max-width: 1440px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */
      color: #0f68e4;
    }
  }
}
</style>
